/* Loading Deals Container */

.loading-deals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(145deg, #1a202e, #222a3d);
  color: #ffffff;
  text-align: center;
  padding: 0 20px;
}

/* Loading Spinner */

.loading-spinner {
  width: 70px;
  height: 70px;
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-top: 8px solid var(--accent-color);
  border-radius: 50%;
  animation: spin 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  box-shadow: 0 0 20px rgba(var(--accent-color-rgb), 0.5);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loading Text */

.loading-text {
  margin-top: 1.5rem;
  font-size: 1.8rem;
  font-weight: 600;
  color: #f7f7f7;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  animation: fadeIn 1.2s ease-in-out infinite alternate;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

@keyframes fadeIn {
  0% {
    opacity: 0.6;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Footer Note */

.footer-note {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #aaaaaa;
  font-style: italic;
  text-align: center;
}

/* CSS Variables */

:root {
  --accent-color: #d21f04;
  --accent-color-rgb: 210, 31, 4;
}
