/* src/components/LoadingDeals.css */

.loading-deals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8f6; /* Soft green background */
}

.loading-spinner {
  width: 60px;
  height: 60px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #28a745; /* Primary green for the spinner */
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 1.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  animation: fadeIn 1s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
