/* Driver Info Container */

.driver-info {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Section Headers */

h2 {
  text-align: left;
  color: var(--accent-color);
  margin-bottom: 20px;
}

/* Driver Section */

.driver-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Form Rows and Groups */

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Form Labels and Inputs */

label {
  font-weight: bold;
  font-size: 14px;
  color: var(--primary-color);
}

input,
select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

/* Toggle Buttons */

.toggle-buttons {
  display: flex;
  gap: 10px;
}

.toggle-buttons button {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #d7d7d7;
  cursor: pointer;
  font-size: 16px;
}

.toggle-buttons button.active {
  background-color: var(--accent-color);
  color: #fff;
}

/* Form Grid */

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Add Driver Button */
.add-driver {
  display: inline-block;
  margin: 20px 0;
  background: none;
  color: var(--accent-color);
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  text-transform: uppercase;
}

.add-driver:hover {
  background-color: #fff;
  color: #ef1f1f;
}

/* Next Step Button */

.next-step {
  width: 100%;
  padding: 15px;
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.next-step:hover {
  background-color: #ef1f1f;
}

.next-step:active {
  background-color: #d62e2e;
}

/* Responsive Design */

@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .driver-info {
    padding: 10px;
  }
  .driver-section {
    gap: 10px;
  }
  .form-row {
    flex-direction: column;
  }
  .form-group {
    gap: 5px;
  }
}

/* CSS Variables */

:root {
  --primary-color: #333;
  --accent-color: #cf0e1e;
}
