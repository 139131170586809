.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* src/App.css */
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f9fa; /* Soft gray for page background */
  color: #343a40; /* Dark gray for text */
  margin: 0;
  padding: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Default styling for larger screens (tablet/desktop) is already in place from previous steps */

/* For tablets (768px and below) */
@media (max-width: 768px) {
  .form-container {
    width: 90%;
    padding: 1.5rem;
  }

  button {
    width: 100%; /* Full-width buttons for easier tapping */
    margin: 1rem 0; /* Add spacing between buttons */
  }

  .buttons-container {
    display: flex;
    justify-content: space-between; /* Evenly space the buttons */
    flex-wrap: wrap; /* Wrap if needed for smaller screens */
  }
}

/* For mobile screens (480px and below) */
@media (max-width: 480px) {
  .form-container {
    width: 95%;
    padding: 1rem;
    font-size: 0.9rem; /* Reduce font size slightly for better fit */
  }

  h1 {
    font-size: 1.5rem; /* Smaller header on mobile */
  }

  select,
  input {
    padding: 0.5rem;
    font-size: 1rem;
  }

  button {
    padding: 0.6rem 1rem;
  }
}

/* Page transition animation */
.page-enter {
  opacity: 0;
  transform: scale(0.95);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transform: scale(1.05);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;
}
