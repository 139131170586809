/* General Header Styles */
.header {
  width: 100%;
  padding: 1rem 2rem;
  background-color: #1e2d3d; /* Maintain the dark background for a professional look */
  color: #ffffffc3; /* Text color is white for contrast */
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for subtle effect */
  transition: background-color 0.3s ease;
  height: 175px; /* Set a height to fit the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent the image from overflowing the header container */
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 100%;
  background-image: url("../../images/flag.jpeg"); /* Path to your banner image */
  background-size: cover; /* Cover the whole container */
  background-position: center; /* Center the image */
  opacity: 0.8; /* Set opacity to make the image slightly faded */
  z-index: -1; /* Place the image behind the header content */
}

/* Header Content Container */
.header-content {
  display: flex;
  justify-content: center; /* Center all header content */
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column; /* Stack site name and navigation vertically */
  position: relative; /* Ensure it stays above the background image */
  z-index: 1; /* Keep the content above the banner image */
}

/* Site Name Styling */
.site-name {
  font-family: "Playfair Display", serif; /* Elegant serif font for branding */
  font-size: 2.4rem;
  font-weight: 700; /* Bold for emphasis */
  color: #ffffff; /* White color to stand out on dark background */
  letter-spacing: 0.5px; /* Slightly wider spacing for a clean, refined look */
  text-align: center; /* Center text within its container */
  margin-bottom: 0.5rem; /* Space between site name and navigation */
}

/* Navigation Styling */
.nav {
  display: flex;
  gap: 2rem;
  justify-content: center; /* Center navigation links */
}

.nav a {
  font-family: "Open Sans", sans-serif; /* Clean, modern sans-serif font */
  font-weight: 600; /* Semi-bold for emphasis */
  font-size: 1.1rem;
  color: #d1d1d1; /* Light grey text for contrast against dark background */
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  padding: 0.3rem 0;
}

.nav a:hover {
  color: #a8c5e2; /* Light blue for a subtle, attractive hover effect */
  transform: translateY(-2px); /* Slight lift for visual interactivity */
  border-bottom: 2px solid #a8c5e2; /* Add underline on hover */
}

/* Responsive Header Styling */
@media (max-width: 768px) {
  .header-content {
    align-items: center; /* Keep all elements centered */
  }

  .nav {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
}

/* Styling for the tagline below the site name */
/* Styling for the tagline below the site name */
.headline-tagline {
  font-family: "Lato", sans-serif; /* Lato is a refined and modern sans-serif font */
  font-size: 1rem; /* Smaller font size for a subtle look */
  font-weight: 300; /* Light weight for an understated style */
  color: #eeecec; /* Light color for a soft, professional look */
  text-align: center; /* Align to match the site name */
  margin-top: 0.2rem; /* Small space between site-name and tagline */
  letter-spacing: 0.2px; /* Slight letter spacing for readability */
  line-height: 1.5; /* Line height to improve readability */
}
