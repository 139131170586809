/* General Styling */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: #333;
}

.title {
  font-size: 3rem; /* Large, bold header */
  margin: 20px 0;
  color: #222a3d; /* Sophisticated navy color */
  background-color: #f8f8f8;
  padding: 10px 50px;
  font-family: "Roboto Slab", serif;
  font-weight: 200;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.2;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-section {
  height: 460px;
  background-image: url("../../images/insurance1.jpeg");
  background-size: cover;
  background-position: center 50%;
  padding: 180px 80px;
  background-size: cover; /* Ensures image covers the container */
  background-repeat: no-repeat;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin: 20px 0;
  color: #333;
}

.form-container {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: inline-block;
  text-align: center;
}

.form-container label {
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  color: #333;
}

.form-container input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container button {
  width: 100%;
  background-color: #0fae1f;
  color: #fff;
  padding: 10px 0;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-container button:hover {
  background-color: #0fae1f;
}

.error-message {
  color: #e63946;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Partners Section */
.partners {
  margin-top: 30px;
  padding: 20px 0;
  background-color: #f8f8f8;
  text-align: center;
}

.partner-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.partner-logos img {
  max-width: 150px;
  height: auto;
  object-fit: contain;
}

.partner-logos span {
  font-size: 0.9rem;
  color: #555;
}

.zip-code-input {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .partner-logos img {
    max-width: 100px;
  }
  .partner-logos {
    gap: 15px;
  }
}
