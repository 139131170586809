/* General styling for containers */
.form-container {
  width: 80%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff; /* White background for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 8px;
  animation: fadeIn 0.5s ease-in-out; /* Fade-in animation when a form step is rendered */
}

/* Styling headers */
h1 {
  color: #242524; /* Darker green for headers for more contrast */
  font-size: 2rem; /* Make the font size larger for emphasis */
  font-weight: bold; /* Emphasize the title */
  text-align: center; /* Keep the header centered */
  margin-bottom: 2rem; /* Add more spacing below the header */
  font-family: "Roboto", sans-serif; /* Add a custom Google Font (e.g., Roboto) */
}

/* Labels and form elements */
label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

select,
input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* Styling buttons */
button {
  background-color: #28a745; /* Primary button color */
  color: #ffffff; /* White text */
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transition for smooth changes */
  margin-right: 1rem;
}

button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

button:active {
  transform: scale(0.95); /* Press-in effect for click */
}

/* Green button effect when active (for staying green until navigation completes) */
.option-button.green-active {
  background-color: #28a745 !important; /* Set the background to green */
  color: white !important; /* Change text color to white for contrast */
  transition: background-color 0.3s ease-in-out; /* Smooth transition to green */
}

/* Styling for the options container */
.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

/* Styling for each option button - box style */
.option-button {
  flex: 1 1 calc(45% - 1.5rem); /* Each button takes roughly 45% of the container, leaving space for the gap */
  padding: 2rem; /* Large padding for a box-like appearance */
  background-color: #ffffff;
  color: #343a40;
  border: 2px solid #ced4da;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, border-color 0.3s ease,
    transform 0.3s ease, box-shadow 0.3s ease;
}

.option-button:hover {
  background-color: #e9f7ef; /* Light green for hover effect */
  border-color: #28a745;
  transform: translateY(-8px); /* Slight lift on hover for interactivity */
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
}

.option-button.selected {
  background-color: #28a745;
  color: #ffffff;
  border-color: #218838;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .option-button {
    flex: 1 1 100%; /* Stack all buttons vertically for smaller screens */
  }
}

/* Page transition animation for smoother effect */
.fade-slide-enter {
  opacity: 0;
  transform: translateX(-100%); /* Start from the left side */
}

.fade-slide-enter-active {
  opacity: 1;
  transform: translateX(0); /* Slide into the center */
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.fade-slide-exit {
  opacity: 1;
  transform: translateX(0); /* Start from the center */
}

.fade-slide-exit-active {
  opacity: 0;
  transform: translateX(100%); /* Slide out to the right */
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

/* Progress bar container */
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0; /* Light gray background */
  border-radius: 8px;
  margin-bottom: 1.5rem;
  height: 1rem;
  overflow: hidden;
}

/* Actual progress bar with animation */
.progress-bar {
  height: 100%;
  background: linear-gradient(
    90deg,
    #28a745,
    #34c759
  ); /* Gradient for a more exciting effect */
  border-radius: 8px 0 0 8px; /* Rounded left edge */
  transition: width 1s cubic-bezier(0.25, 1, 0.5, 1); /* Smooth easing transition for more dynamic effect */
}

/* Animation for form entry */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Container for navigation buttons */
.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

/* Additional responsive styles */
@media (max-width: 768px) {
  .form-container {
    width: 90%;
    padding: 1.5rem;
  }

  button {
    width: 100%; /* Full-width buttons for easier tapping */
    margin: 1rem 0; /* Add spacing between buttons */
  }

  .buttons-container {
    display: flex;
    justify-content: space-between; /* Evenly space the buttons */
    flex-wrap: wrap; /* Wrap if needed for smaller screens */
  }
}

@media (max-width: 480px) {
  .form-container {
    width: 95%;
    padding: 1rem;
    font-size: 0.9rem; /* Reduce font size slightly for better fit */
  }

  h1 {
    font-size: 1.5rem; /* Smaller header on mobile */
  }

  select,
  input {
    padding: 0.5rem;
    font-size: 1rem;
  }

  button {
    padding: 0.6rem 1rem;
  }
}
.form-image {
  width: 100%; /* Let the image take up the full width of its container */
  max-width: 450px; /* Limit the width so it does not get too large */
  height: auto; /* Maintain aspect ratio */
  max-height: 250px; /* Restrict height for consistency */
  border-radius: 12px; /* Slightly larger rounded corners for a softer, more modern feel */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow for added depth */
  object-fit: cover; /* Ensure the image covers the container nicely without distortion */
  margin: 0 auto 20px; /* Center the image horizontally, add spacing below */
  display: block; /* Make the image a block element to center it properly */
}

/* Styling for LazyImage to match .banner-image */
.lazy-image-wrapper {
  width: 90%; /* Make image take full width of its container */
  max-width: 500px; /* Limit maximum size to maintain readability */
  border-radius: 8px; /* Optional: rounded corners */
  overflow: hidden; /* Ensure both images stay within rounded borders */
  position: relative; /* Set position relative to properly align children */
  padding-top: 56.25%; /* This maintains a 16:9 aspect ratio */
  height: 0; /* The height is determined by the padding-top */
}

.lazy-placeholder {
  position: absolute; /* Make sure placeholder and image are at the same position */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Fill the entire container */
  object-fit: cover; /* Ensure the placeholder fills its container nicely */
  filter: blur(10px);
  transition: opacity 0.5s ease-in-out; /* Smooth transition for fading out */
}

.lazy-image {
  position: absolute; /* Make sure image sits on top of the placeholder */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Maintain container's height */
  object-fit: cover; /* Ensure the final image fills its container nicely */
  transition: opacity 0.5s ease-in-out; /* Smooth transition for fading in */
  opacity: 0; /* Start with the image hidden */
}

/* Styling for the image */
.banner-image {
  width: 90%; /* Make image take full width of its container */
  height: auto; /* Maintain aspect ratio */
  max-width: 500px; /* Limit maximum size to maintain readability */
  border-radius: 8px; /* Optional: rounded corners */
  object-fit: cover; /* Ensure the image fills its container nicely */
}
