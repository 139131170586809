.offers-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
}
.offers-header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 0 1.5rem;
}

.offers-header h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 0.75rem;
  line-height: 1.2;
  text-align: center;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.offers-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 2rem;
}
.offer-card {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.offer-card:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}
.offer-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 1.5rem;
}
.offer-details h3 {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.offer-details p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}
.recommended-badge {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  color: #fff;
  padding: 0.4rem 0.8rem;
  border-radius: 0 8px 8px 0;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}
.get-quote-button {
  background: linear-gradient(90deg, #4caf50, #43a047);
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-top: auto;
  align-self: center;
  text-align: center;
  width: 100%;
}

.get-quote-button:hover {
  background: linear-gradient(90deg, #43a047, #388e3c);
  transform: translateY(-2px);
}

.get-quote-button:active {
  transform: translateY(0);
}
.get-quote-button {
  background: linear-gradient(90deg, #4caf50, #43a047);
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  margin-top: auto;
  align-self: center;
  text-align: center;
  width: 100%;
}

.get-quote-button:hover {
  background: linear-gradient(90deg, #43a047, #388e3c);
  transform: translateY(-2px);
}

.get-quote-button:active {
  transform: translateY(0);
}
.powered-by-text {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.95rem;
  color: #777;
}
@media (max-width: 768px) {
  .offers-header h2 {
    font-size: 2rem;
  }

  .offers-header .subtext {
    font-size: 1rem;
  }

  .offer-card {
    padding: 1.25rem;
  }

  .offer-details h3 {
    font-size: 1.2rem;
  }

  .offer-details p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .offers-header h2 {
    font-size: 1.8rem;
  }

  .offers-header .subtext {
    font-size: 0.9rem;
  }

  .offer-card {
    padding: 1rem;
  }

  .get-quote-button {
    padding: 0.7rem;
    font-size: 0.9rem;
  }
}
:root {
  --primary-color: #333;
}
